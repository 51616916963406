<template>
  <div class="kt-grid kt-grid--ver kt-grid--root">
    <div
      class="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
      id="kt_login"
    >
      <div
        class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile"
      >
        <!--begin::Aside-->
        <div
          class="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
          :style="{ backgroundColor: '#21285f' }"
        >
          <div class="kt-grid__item">
            <a href="#" class="kt-login__logo">
              <img class="logo img-fluid" src="@/assets/media/icons/logo.png" />
            </a>
          </div>
          <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
            <div class="kt-grid__item kt-grid__item--middle">
              <h3 class="kt-login__title">Welcome to Novonordisk!</h3>
            </div>
          </div>
          <div class="kt-grid__item">
            <div class="kt-login__info">
              <div class="kt-login__copyright">
                © 2023 Novonordisk
              </div>
            </div>
          </div>
        </div>
        <!--begin::Aside-->

        <!--begin::Content-->
        <div
          class="kt-grid__item kt-grid__item--fluid kt-grid__item--order-tablet-and-mobile-1 kt-login__wrapper"
        >
          <router-view></router-view>
        </div>
        <!--end::Content-->
      </div>
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<style scoped>
.kt-login__wrapper {
  background-color: white !important;
  background-image: url("~@/assets/media/bg/background-1.svg"),
    url("~@/assets/media/bg/background-2.svg") !important;
  background-position-y: top, bottom !important;
  background-position-x: right, left !important;
  background-repeat: no-repeat, no-repeat !important;
}

.logo {
  width: 130px;
  filter: brightness(0) invert(1);
}
</style>

<script>
import { mapState } from "vuex";

export default {
  name: "auth",
  methods: {},
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/bg/bg-4.jpg";
    },
  },
};
</script>
